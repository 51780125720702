import { XCircleIcon, CheckIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'

export function RedemptionCodeInvalid(props) {
    return (
        <div className={props.hidden ? 'hidden' : "rounded-md bg-red-50 p-4 mb-12"}>
            <div className="flex justify-center">
                <div className="flex-shrink-0 flex self-center">
                    <XCircleIcon className="h-10 w-10 text-red-400 self-center flex mr-10" aria-hidden="true" />
                </div>
                <div>
                    <h3 className="text-md font-semibold text-red-800">{props.redemptionCode} has either been claimed, or does not exist.</h3>
                    <div className="mt-2 text-sm text-red-700">
                        You may re-enter your Redemption Code if the above Redemption Code is incorrect.
                    </div>
                    <button onClick={() => props.resetCode()} className='p-3 m-3 bg-white text-black rounded-lg'>Re-Enter Redemption Code</button>
                </div>
            </div>
        </div>
    )
};

function OrderIdInvalid(props) {
    return (
        <div className={props.hidden ? 'hidden' : "rounded-md bg-red-50 p-4"}>
            <div className="flex justify-center">
                <div className="flex-shrink-0 flex self-center">
                    <XCircleIcon className="h-10 w-10 text-red-400 self-center flex mr-10" aria-hidden="true" />
                </div>
                <div>
                    <h3 className="text-md font-semibold text-red-800">{props.orderId} has either been claimed, or does not exist.</h3>
                    <div className="mt-2 text-sm text-red-700">
                        You may re-enter your Amazon Order Id if the above Order Id is incorrect.
                    </div>
                </div>
            </div>
        </div>
    )
};

function WalmartOrderIdInvalid(props) {
    return (
        <div className={props.hidden ? 'hidden' : "rounded-md bg-red-50 p-4"}>
            <div className="flex justify-center">
                <div className="flex-shrink-0 flex self-center">
                    <XCircleIcon className="h-10 w-10 text-red-400 self-center flex mr-10" aria-hidden="true" />
                </div>
                <div>
                    <h3 className="text-md font-semibold text-red-800">{props.orderId} has either been claimed, or does not exist.</h3>
                    <div className="mt-2 text-sm text-red-700">
                        You may re-enter your Walmart Order Id if the above Order Id is incorrect.
                    </div>
                </div>
            </div>
        </div>
    )
};

function Complete(props) {
    return (
        <div className={props.hidden ? 'hidden' : "pb-5"}>
            <div className="flex justify-center rounded-md bg-green-50 px-4 py-20">
                <div className="flex-shrink-0 flex self-center">
                    <CheckIcon className="h-10 w-10 text-green-400 self-center flex mr-3" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-md font-medium text-green-800">You're Finished!</h3>
                    <div className="mt-2  text-green-700">
                        Confirmation will be sent to {props.email} when your order is processed!
                    </div>
                </div>
            </div>
        </div>
    )
};

export function RedemptionComplete(props) {
    return (
        <div className={props.hidden ? 'hidden' : "pb-5"}>
            <div className="flex justify-center rounded-md bg-green-50 px-4 py-20">
                <div className="flex-shrink-0 flex self-center">
                    <CheckIcon className="h-10 w-10 text-green-400 self-center flex mr-3" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-md font-medium text-green-800">Thank you for participating in our free bottle offer and providing us with valuable feedback!</h3>
                    <div className="mt-2  text-green-700">
                    Please keep an eye on your inbox over the next few weeks for a confirmation email about your free order shipment. We'll also provide a tracking number. 
                    Be sure to check your spam folder as well. If you haven't received an email from us within 14 days, please contact our <a className='underline text-blue-500' href={props.customerServiceLink}>customer service team</a>.
                    </div>
                </div>
            </div>
        </div>
    )
};

function SurveyComplete(props) {
    return (
        <div className={props.hidden ? 'hidden' : "pb-5"}>
            <div className="flex justify-center rounded-md bg-green-50 px-4 py-20">
                <div className="flex-shrink-0 flex self-center">
                    <CheckIcon className="h-10 w-10 text-green-400 self-center flex mr-3" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-xl font-medium text-green-800">You're Finished!</h3>
                    <br />
                    <div className="mt-2  text-green-700">
                    Thanks for completing our survey! <br /><br /> Keep an eye out for any next steps or free gifts*
                    <br />
                    <br />
                    Confirmation will be sent to {props.email} when your order is processed!
                    </div>
                    <br />
                    <sub className='py-12'>
                    *If you qualified for a free gift, please allow 7-14 business days to receive via mail. <br /> Any other promotion may result in email communication.
                    </sub>
                </div>
            </div>
        </div>
    )
};

function RecallSurveyComplete(props) {
    return (
        <div className={props.hidden ? 'hidden' : "pb-5"}>
            <div className="flex justify-center rounded-md bg-green-50 px-4 py-20">
                <div className="flex-shrink-0 flex self-center">
                    <CheckIcon className="h-10 w-10 text-green-400 self-center flex mr-3" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-xl font-medium text-green-800">You're Finished!</h3>
                    <br />
                    <div className="mt-2  text-green-700">
                    Thanks for completing our survey! <br/> <br/> <div className="mt-2  text-green-700">
                        Use code "<b><i>HAVBEET30</i></b>" on your next order to receive a discount.
                    </div> <br /><br /> Keep an eye on your email for any next steps.
                    <br />
                    <br />
                    </div>
                    <br />
                </div>
            </div>
        </div>
    )
};

function GiftCardComplete(props) {
    return (
        <div className={props.hidden ? 'hidden' : "pb-5"}>
            <div className="flex justify-center rounded-md bg-green-50 px-4 py-20">
                <div className="flex-shrink-0 flex self-center">
                    <CheckIcon className="h-10 w-10 text-green-400 self-center flex mr-3" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-xl font-medium text-green-800">You're Finished!</h3>
                    <div className="mt-2  text-green-700">
                        Confirmation will be sent to {props.email} <br /> when your order is verified!
                    </div>
                </div>
            </div>
        </div>
    )
};

function CheckYourEmail(props) {
    return (
        <div className={props.hidden ? 'hidden' : "pb-5"}>
            <div className="flex justify-center rounded-md bg-green-50 px-4 py-20">
                <div className="flex-shrink-0 flex self-center">
                    <CheckIcon className="h-10 w-10 text-green-400 self-center flex mr-3" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-md font-medium text-green-800">Check Your Email</h3>
                    <div className="mt-2  text-green-700">
                        Check your {props.email} inbox for an email from {props.brandInfo.klaviyoEmail} to continue to your survey.
                    </div>
                </div>
            </div>
        </div>
    )
};

function OfferEnded(props) {
    return (
        <div className={props.hidden ? 'hidden' : "pb-5"}>
            <div className="flex justify-center rounded-md bg-green-50 px-4 py-20">
                <div className="flex-shrink-0 flex self-center">
                    <CheckIcon className="h-10 w-10 text-green-400 self-center flex mr-10" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-md font-medium text-green-800">You're Finished!</h3>
                    <div className="mt-2  text-green-700">
                        Shipping confirmation will be sent to {props.email} when your order is processed!
                    </div>
                </div>
            </div>
        </div>
    )
};

function RetryButton(props) {
    return (
        <span className={props.hidden ? 'hidden' : "isolate inline-flex rounded-md shadow-sm justify-center static bottom-0 my-5"}>
            <button
                type="button"
                disabled={props.disableNext}
                onClick={() => { props.dispatch({ type: 'simpleUpdate', field: 'orderId', value: '' }); props.dispatchSteps({ type: 'setCurrent', step: 'Amazon Order Id' }); props.validate({type: 'verifyOrderId', value: null}) } }
                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-md font-semibold disabled:bg-gray-300 disabled:text-white disabled:pointer-events-none text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 w-40 text-center justify-center"
            >
                Re-enter Order Id
            </button>
        </span>
    )
}

export { WalmartOrderIdInvalid, OrderIdInvalid, RetryButton, Complete, OfferEnded, CheckYourEmail, GiftCardComplete, SurveyComplete, RecallSurveyComplete }
